import React from "react";
import { Card, CardBody, Col } from "reactstrap";
import ReactApexChart from "react-apexcharts";

export const ChartBarHorizontal2 = ({ data }) => {

  let labels = [];
  let values = [];
  for(let i = 0; i < data.values.length; i++){
    labels.push(data.values[i].label);
    values.push(data.values[i].value);
  }

  const LineColumnAreaData = {
    series: [{
      data: values,
    }],
    options: {
      chart: {
        height: 350,
        type: 'bar',
        stacked: false,
        toolbar: {
          show: false
        },
      },
    annotations: {
      xaxis: [{
        x: 500,
        borderColor: '#00E396',
        label: {
          borderColor: '#00E396',
          style: {
            color: '#fff',
            background: '#00E396',
          },
          text: '',
        }
      }],
      yaxis: [{
        label: {
          text: ''
        }
      }]
    },
    plotOptions: {
      bar: {
        horizontal: true,
      }
    },
    dataLabels: {
      enabled: true
    },
    xaxis: {
      categories: labels,
    },
    grid: {
      xaxis: {
        lines: {
          show: true
        }
      }
    },
    yaxis: {
      reversed: false,
      axisTicks: {
        show: true  
      }
    },
    tooltip: {
      y: {
          formatter: undefined,
          title: {
              formatter: () => '',
          },
      },
    },
    },
  }

  return (
    <Col xl={data.half ? 6 : 12} sm={12}>
      <Card>
        <CardBody>
          <div>
            <div>
                <h5 className="card-title">
                  { data.title }
                </h5>
            </div>
            <div>
              <ReactApexChart
                options={LineColumnAreaData.options}
                series={LineColumnAreaData.series}
                type="bar"
                height="350"
                stacked= "false"
                className="apex-charts"
              />
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
}