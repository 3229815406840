import React from "react";
import { Card, CardBody, Col } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import { formatMoney } from "../../../helpers/general_helper";

export const ChartBar3 = ({ data }) => {

  let labels1 = [];
  let values1 = [];
  for(let i = 0; i < data.values1.data.length; i++){
    labels1.push(data.values1.data[i].label);
    values1.push(data.values1.data[i].value);
  }

  let values2 = [];
  for(let i = 0; i < data.values2.data.length; i++){
    values2.push(data.values2.data[i].value);
  }

  let values3 = [];
  const hasAxis3 = data.values3;
  if(hasAxis3){
    for(let i = 0; i < data.values3.data.length; i++){
      values3.push(data.values2.data[i].value);
    }
  }

  const series = [];
  series.push({
    name: data.values1.title,
    type: 'area',
    data: values1
  });
  series.push({
    name: data.values2.title,
    type: 'line',
    data: values2
  });

  if(hasAxis3){
    series.push({
      name: data.values3.title,
      type: 'line',
      data: values3
    });
  }

  const DashedLineData = {
    series,
    options: {
      chart: {
        height: 350,
        type: 'line',
        zoom: { enabled: !1 },
        toolbar: { show: !1 }
      },
      stroke: {
        curve: 'smooth'
      },
      fill: {
        type:'solid',
        opacity: [0.35, 1],
      },
      labels: labels1,
      xaxis: {
        type: 'datetime',
      },
      markers: {
        size: 0
      },
      yaxis: [
        {
          title: {
            text: data.values1.title,
          },
        },
        {
          opposite: true,
          title: {
            text: data.values2.title,
          },
        },
      ],
      tooltip: {
        shared: true,
        intersect: false,
        y: {
          formatter: function (y, l) {
            if(typeof y !== "undefined") {
              if(data.currencyCode && data.currencyCode !== ''){
                return formatMoney(data.currencyCode, y);
              }
              if(l.seriesIndex === 0 && data.values1 && data.values1.currencyCode !== undefined && data.values1.currencyCode !== ''){
                return formatMoney(data.values1.currencyCode, y);
              }
              if(l.seriesIndex === 1 && data.values1 && data.values2.currencyCode !== undefined && data.values2.currencyCode !== ''){
                return formatMoney(data.values2.currencyCode, y);
              }
            }
            return y;
          }
        }
      }
    },
  }

  return (
    <Col xl={12} sm={12}>
      <Card>
        <CardBody>
          <div>
            <div>
                <h5 className="card-title">
                  { data.title }
                </h5>
            </div>
            <div>
            <ReactApexChart
              options={DashedLineData.options}
              series={DashedLineData.series}
              type="line"
              height="380"
              className="apex-charts"
            />
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
}