import React from "react";
import { Card, CardBody, Col } from "reactstrap";
import ReactApexChart from "react-apexcharts";

export const ChartCircleBox2 = ({ data }) => {

  const series = [data.percent];
  const radialoptions = {
      chart: {
          type: 'radialBar',
          sparkline: {
              enabled: true
          }
      },
      dataLabels: {
          enabled: false
      },
      colors: ['#B452DA'],
      stroke: {
          lineCap: 'round'
      },
      plotOptions: {
          radialBar: {
              hollow: {
                  margin: 0,
                  size: '70%'
              },
              track: {
                  margin: 0,
              },
  
              dataLabels: {
                  name: {
                      show: false
                  },
                  value: {
                      offsetY: 5,
                      show: true
                  }
              }
          }
      }
  };

  return (
    <Col xl={4} sm={6}>
      <Card>
        <CardBody>
          <div>
            <p className="mb-1"><b>{ data.title }</b></p>
          </div>
          <div className="d-flex text-muted justify-content-center">
            <div className="flex-shrink-0 me-3 align-self-center">
              <div id="radialchart-1" className="apex-charts" dir="ltr">
                <ReactApexChart
                  options={radialoptions}
                  series={series}
                  type="radialBar"
                  height="200"
                  width= "200"
                />
              </div>
            </div>
          </div>
          { data.footer &&
            <div className="d-flex justify-content-center align-items-center text-center mb-0">
              <i className={`text-${data.footer.color} fa fa-circle align-bottom mr-2`}></i>
              <div style={{ marginLeft: 10 }}>
                { data.footer.text }
              </div>
            </div>
          }
        </CardBody>
      </Card>
    </Col>
  );
}