import React from "react";
import { Card, CardBody, Col } from "reactstrap";
import ReactApexChart from "react-apexcharts";

export const ChartPie1 = ({ data }) => {

  let labels = [];
  let values = [];
  const colors = data.colors;

  for(let i = 0; i < data.values.length; i++){
    labels.push(data.values[i].label);
    values.push(data.values[i].value === null ? 0 : data.values[i].value);
  }

  const PieColumnAreaData = {
    series: values,
    options: {
      chart: {
        type: 'donut',
      },
      labels: labels,
      colors: colors,
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }]
    },
  }

  return (
    <Col xl={data.half ? 6 : 12} sm={12}>
      <Card>
        <CardBody>
          <div>
            <div>
                <h5 className="card-title">
                  { data.title }
                </h5>
            </div>
            <div>
              <ReactApexChart
                options={PieColumnAreaData.options}
                series={PieColumnAreaData.series}
                type="donut"
                height="300"
                stacked= "false"
                className="apex-charts"
              />
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
}