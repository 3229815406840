import React, { useEffect, useState } from "react";

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
} from "reactstrap";
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { defaultTitle } from "../../constants/layout";
import { Loading } from "../../components/Common/Loading";

import API from "../../services/api";
import { aAlert, goalFormat } from "../../helpers/general_helper";
import { PermissionOrDie } from "../../helpers/auth_helper";
import { Button } from "../../components/Common/Button";

const GoalsCreatePage = () => {
  document.title = `Nova Meta | ${defaultTitle}`;
  PermissionOrDie('users_create'); // TODO

  const [is_loading, setis_loading] = useState(false);
  const [is_loading_post, setis_loading_post] = useState(false);

  const [data, setdata] = useState([]);
  useEffect(() => {
    API.get("goals/funnel-stages")
    .then(({ data }) => {
        setdata(data);
    })
    .catch((error) => {
        aAlert(error?.response?.data?.message ?? error.message, "error");
    })
    .then(() => {
        setis_loading(false);
    });
  }, []);

  // Form validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: "",
      goal: "",
      date_init: "",
      date_end: "",
      funnel_stage: "",
    },
    validationSchema: Yup.object({
      title: Yup.string(),
      goal: Yup.string().required("Por favor, informe a meta."),
      date_init: Yup.date()
                   .required("Por favor, informe a data de início."),
      date_end: Yup.date()
                   .min(
                     Yup.ref('date_init'),
                     "A data final deve ser maior ou igual à data de início."
                   )
                   .required("Por favor, informe a data final."),
      funnel_stage: Yup.string().required("Por favor, informe a etapa do funil."),
    }),
    onSubmit: (values) => {
      console.log(values.title);
      postGoal({
        title: values.title,
        funnel_stage: values.funnel_stage,
        goal: values.goal.replace(/[^0-9.]/g, ''),
        date_start: values.date_init,
        date_end: values.date_end,
      });
    },
  });

  const postGoal = (values) => {
    setis_loading_post(true);
    
    API.post("goals", {
      ...values
    })
      .then(() => {
        window.location = '/goals?success=1';
      })
      .catch((error) => {
        setis_loading_post(false);
        aAlert(error?.response?.data?.message ?? error.message, "error");
      });
  }

  const handleFunnelStageChange = (event) => {
    const newValues = {
      ...validation.values,
      [event.target.name]: event.target.value,
      goal: '',
    };
    validation.setValues(newValues);
    validation.handleChange(event);
  }

  const handleGoalChange = (event) => {
    const formattedValue = goalFormat(validation.values.funnel_stage, event.target.value, true);
    event.target.value = formattedValue;
    validation.handleChange(event);
  };

  return (
    <React.Fragment>
      <Loading isLoading={is_loading} />
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title="Metas"
            breadcrumbMother="Lista de Metas"
            breadcrumbItem="Nova Meta"
          />
          <Form
            className="needs-validation"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <Row>
                      <Col md="12">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom15">Descrição da Meta</Label>
                          <Input
                            name="title"
                            placeholder=""
                            type="text"
                            className="form-control"
                            id="validationCustom0555"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.title || ""}
                            invalid={
                              validation.touched.title &&
                              validation.errors.title
                                ? true
                                : false
                            }
                          />
                          {validation.touched.title &&
                          validation.errors.title ? (
                            <FormFeedback type="invalid">
                              {validation.errors.title}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom02">Etapa do Funil</Label>
                          <Input 
                            className="form-control"
                            name="funnel_stage"
                            id="validationCustom03"
                            onChange={handleFunnelStageChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.funnel_stage || ""}
                            invalid={
                              validation.touched.funnel_stage &&
                              validation.errors.funnel_stage
                                ? true
                                : false
                            }
                            type="select"
                          >
                            <option>Selecione</option>
                            { data.map((stage) => 
                              <option value={ stage.value }>{ stage.label }</option>
                            ) }
                          </Input>
                          {validation.touched.funnel_stage &&
                          validation.errors.funnel_stage ? (
                            <FormFeedback type="invalid">
                              {validation.errors.funnel_stage}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom01">Meta</Label>
                          <Input
                            name="goal"
                            placeholder=""
                            type="text"
                            className="form-control"
                            onChange={handleGoalChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.goal || ""}
                            invalid={
                              validation.touched.goal &&
                              validation.errors.goal
                                ? true
                                : false
                            }
                          />
                          {validation.touched.goal &&
                          validation.errors.goal ? (
                            <FormFeedback type="invalid">
                              {validation.errors.goal}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom02">Data Inicio</Label>
                          <Input
                            name="date_init"
                            placeholder=""
                            type="date"
                            className="form-control"
                            id="validationCustom02"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.date_init || ""}
                            invalid={
                              validation.touched.date_init &&
                              validation.errors.date_init
                                ? true
                                : false
                            }
                          />
                          {validation.touched.date_init &&
                          validation.errors.date_init ? (
                            <FormFeedback type="invalid">
                              {validation.errors.date_init}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom02">Data Fim</Label>
                          <Input
                            name="date_end"
                            placeholder=""
                            type="date"
                            className="form-control"
                            id="validationCustom02"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.date_end || ""}
                            invalid={
                              validation.touched.date_end &&
                              validation.errors.date_end
                                ? true
                                : false
                            }
                          />
                          {validation.touched.date_end &&
                          validation.errors.date_end ? (
                            <FormFeedback type="invalid">
                              {validation.errors.date_end}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row className="mb-5">
              <Col md="12">
                <Button 
                  style={{ width: "100%" }}
                  text="Criar"
                  isLoading={is_loading_post}
                />
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default GoalsCreatePage;
