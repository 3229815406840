import { getLoggedinUser } from "./api_helper";

export const HasPermission = (permissionName) => {
  if (getLoggedinUser()) {
    const obj = getLoggedinUser();
    if(typeof permissionName === 'string'){
      return obj.permissions.includes(permissionName);
    }
    else if (Array.isArray(permissionName)) {
      return permissionName.some(permission => obj.permissions.includes(permission));
    }
  }
  return false;
};

export const PermissionOrDie = (permissionName) => {
  if(!HasPermission(permissionName)){
    window.location = '/error/401';
  }
}