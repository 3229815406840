import React from "react";
import { Card, CardBody, Col } from "reactstrap";
import ReactApexChart from "react-apexcharts";

export const ChartBar6 = ({ data }) => {

  const series = data.series;
  const dates = data.dates;
  const colors = data.colors;

  console.log(series, dates, colors);

  const DashedLineData = {
    series: series,
    options: {
      chart: {
        type: 'bar',
        height: 430
      },
      plotOptions: {
        bar: {
          horizontal: false,
          dataLabels: {
            position: 'top',
          },
        }
      },
      dataLabels: {
        enabled: true,
        offsetX: -6,
        style: {
          fontSize: '12px',
          colors: ['#fff']
        }
      },
      stroke: {
        show: true,
        width: 1,
        colors: ['#fff']
      },
      tooltip: {
        shared: true,
        intersect: false
      },
      xaxis: {
        categories: dates,
      },
      colors: colors
    },
  }

  return (
    <Col xl={12} sm={12}>
      <Card>
        <CardBody>
          <div>
            <div>
                <h5 className="card-title">
                  { data.title }
                </h5>
            </div>
            <div>
            <ReactApexChart
              options={DashedLineData.options}
              series={DashedLineData.series}
              type="bar"
              height="380"
              className="apex-charts"
            />
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
}