import React from "react";
import { defaultColors } from "../../../constants/layout";
export const NoResults = ({ title, message }) => {
  return (
    <div className="noresult">
        <div className="text-center">
            <h5 className="mt-2" style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
              <i
                className="mdi mdi-emoticon-sad-outline"
                style={{
                  marginRight: "5px",
                  fontSize: 24,
                  color: defaultColors.PINK,
                }}
              ></i>
              { title }
            </h5>
            <p className="text-muted mb-0">{ message }</p>
        </div>
    </div>
  );

}