import React from "react";
import { Card, CardBody, Col } from "reactstrap";
import ReactApexChart from "react-apexcharts";

export const ChartLine1 = ({ data }) => {

  let values = [];
  let categories = [];
  for(let i = 0; i < data.values.length; i++){
    let v = [];
    for(let j = 0; j < data.values[i].values.length; j++){
      v.push(data.values[i].values[j].value);
      categories.push(data.values[i].values[j].label);
    }
    values.push({
      name: data.values[i].title,
      data: v,
    });
  }
  
  categories = categories.filter((item, index, self) => self.indexOf(item) === index);

  const LineColumnAreaData = {
    series: values,
    options: {
      chart: {
        height: 350,
          type: 'line',
          stacked: true,
          toolbar: {
              show: false
          },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        width: [5, 7, 5],
        curve: 'straight',
        dashArray: [0, 8, 5]
      },
      title: {
        text: '',
        align: 'left'
      },
      legend: {
        tooltipHoverFormatter: function(val, opts) {
          return val + ' - <strong>' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + '</strong>'
        }
      },
      markers: {
        size: 0,
        hover: {
          sizeOffset: 6
        }
      },
      xaxis: {
        categories: categories,
        labels: {
          formatter: function (val) {
            try {
              if(`${val}`.split('-').length === 3){
                const parts = val.split('-');
                const date = new Date(parts[0], parts[1] - 1, parts[2]);
                if(date instanceof Date && !isNaN(date)){
                  return date.toLocaleDateString('en-US', { day: '2-digit', month: 'short' });
                }
              }
            }
            catch(e){
              return val;  
            }
            return val;
          },
        }
      },
      tooltip: {
        y: [
          {
            title: {
              formatter: function (val) {
                return val;
              }
            }
          },
          {
            title: {
              formatter: function (val) {
                return val;
              }
            }
          },
          {
            title: {
              formatter: function (val) {
                return val;
              }
            }
          }
        ]
      },
      grid: {
        borderColor: '#f1f1f1',
      }
    }
  }

  return (
    <Col xl={12} sm={12}>
      <Card>
        <CardBody>
          <div>
            <div>
                <h5 className="card-title">
                  { data.title }
                </h5>
            </div>
            <div>
              <ReactApexChart
                options={LineColumnAreaData.options}
                series={LineColumnAreaData.series}
                type="line"
                height="350"
                stacked= "false"
                className="apex-charts"
              />
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
}