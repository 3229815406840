import React from "react";
import { ChartCircleBox1 } from "./circleBox1";
import { ChartCircleBox2 } from "./circleBox2";
import { ChartBar1 } from "./bar1";
import { ChartTitle } from "./title";
import { ChartTable } from "./table";
import { DataValueBox1 } from "./dataValueBox1";
import { ChartBar2 } from "./bar2";
import { ChartBar3 } from "./bar3";
import { ChartBarHorizontal1 } from "./barHorizontal1";
import { ChartLine1 } from "./line1";
import { ChartBarHorizontal2 } from "./barHorizontal2";
import { ChartPie1 } from "./pie1";
import { ChartBar4 } from "./bar4";
import { ChartPieBox1 } from "./pieBox1";
import { Target } from "./target";
import { ChartBar5 } from "./bar5";
import { ChartBar6 } from "./bar6";
import { ChartLine2 } from "./line2";

export const Chart = ({ type, data }) => {
  switch(type){
    case 'circleBox1' :
      return <ChartCircleBox1 data={data} />;
    case 'circleBox2' :
      return <ChartCircleBox2 data={data} />;
    case 'bar1' :
      return <ChartBar1 data={data} />;
    case 'bar2' :
      return <ChartBar2 data={data} />;
    case 'bar3' :
      return <ChartBar3 data={data} />;
    case 'bar4' :
      return <ChartBar4 data={data} />;
    case 'bar5' :
      return <ChartBar5 data={data} />;
    case 'bar6' :
      return <ChartBar6 data={data} />;
    case 'title' :
      return <ChartTitle data={data} />;
    case 'table' :
      return <ChartTable data={data} />;
    case 'dataValueBox1' :
      return <DataValueBox1 data={data} />;
    case 'barHorizontal1' :
      return <ChartBarHorizontal1 data={data} />;
    case 'barHorizontal2' :
      return <ChartBarHorizontal2 data={data} />;
    case 'line1' :
      return <ChartLine1 data={data} />;
    case 'line2' :
      return <ChartLine2 data={data} />;
    case 'pie1' :
      return <ChartPie1 data={data} />;
    case 'pieBox1' :
      return <ChartPieBox1 data={data} />;
    case 'target' :
      return <Target data={data} />;
    default:
      return <></>;
  }
}