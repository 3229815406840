import React, { useEffect, useState } from "react";

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
} from "reactstrap";
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { defaultTitle } from "../../constants/layout";
import { Loading } from "../../components/Common/Loading";

import API from "../../services/api";
import { aAlert } from "../../helpers/general_helper";
import { PermissionOrDie } from "../../helpers/auth_helper";
import { useParams } from "react-router-dom";
import { Button } from "../../components/Common/Button";

const UsersEditPage = () => {
  document.title = `Editar Usuário | ${defaultTitle}`;
  PermissionOrDie('users_edit');

  const { id } = useParams();

  const [is_loading, setis_loading] = useState(true);
  const [is_loading_post, setis_loading_post] = useState(false);

  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userRoleId, setUserRoleId] = useState('');

  const [isMe, setIsMe] = useState(false);

  const [data, setdata] = useState([]);
  useEffect(() => {
    API.get(`users/${id}`)
    .then(({ data }) => {
      setUserName(data.name);
      setUserEmail(data.username);
      isMyUser(data.me);
      setTimeout(() => {
        getPermissions(data.roles[0].id);
      }, 1000);
    })
    .catch((error) => {
      setis_loading(false);
      aAlert(error?.response?.data?.message ?? error.message, "error");
    });
  }, []);

  const getPermissions = (roleId) => {
    API.get("roles")
    .then(({ data }) => {
        setdata(data);
        setUserRoleId(roleId);
    })
    .catch((error) => {
        aAlert(error?.response?.data?.message ?? error.message, "error");
    })
    .then(() => {
        setis_loading(false);
    });
  }

  const isMyUser = (isMe) => {
    setIsMe(isMe);
    if(isMe){
      setTimeout(() => {
        let inputs = document.querySelectorAll('input');
        inputs.forEach(function(input) {
            input.disabled = true;
        });
      }, 200);
    }
  }

  // Form validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      user_name: userName,
      user_email: userEmail,
      user_role: userRoleId,
    },
    validationSchema: Yup.object({
      user_name: Yup.string().required("Por favor, informe o nome do usuário."),
      user_email: Yup.string().email("Por favor, informe um e-mail válido.").required("Por favor, informe o e-mail do usuário."),
      user_role: Yup.string().required("Por favor, informe a permissão do usuário."),
    }),
    onSubmit: (values) => {
      postUser({
        name: values.user_name,
        email: values.user_email,
        role_id: values.user_role,
      });
    },
  });

  const postUser = (values) => {
    setis_loading_post(true);
    
    API.put(`users/${id}`, {
      ...values
    })
      .then(() => {
        window.location = '/users?success=2';
      })
      .catch((error) => {
        setis_loading_post(false);
        aAlert(error?.response?.data?.message ?? error.message, "error");
      });
  }

  return (
    <React.Fragment>
      <Loading isLoading={is_loading} />
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title="Usuários"
            breadcrumbMother="Lista de Usuários"
            breadcrumbItem="Editar Usuário"
          />
          <Form
            className="needs-validation"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <Row>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom01">Nome</Label>
                          <Input
                            name="user_name"
                            placeholder=""
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.user_name || ""}
                            invalid={
                              validation.touched.user_name &&
                              validation.errors.user_name
                                ? true
                                : false
                            }
                          />
                          {validation.touched.user_name &&
                          validation.errors.user_name ? (
                            <FormFeedback type="invalid">
                              {validation.errors.user_name}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom02">E-mail</Label>
                          <Input
                            name="user_email"
                            placeholder=""
                            type="text"
                            className="form-control"
                            id="validationCustom02"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.user_email || ""}
                            invalid={
                              validation.touched.user_email &&
                              validation.errors.user_email
                                ? true
                                : false
                            }
                            readOnly={true}
                          />
                          {validation.touched.user_email &&
                          validation.errors.user_email ? (
                            <FormFeedback type="invalid">
                              {validation.errors.user_email}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom02">Permissão</Label>
                          <Input 
                            className="form-control"
                            name="user_role"
                            id="validationCustom03"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.user_role || ""}
                            invalid={
                              validation.touched.user_role &&
                              validation.errors.user_role
                                ? true
                                : false
                            }
                            type="select"
                          >
                            <option>Selecione</option>
                            { data.map((role) => 
                              <option value={ role.id }>{ role.name }</option>
                            ) }
                          </Input>
                          {validation.touched.user_role &&
                          validation.errors.user_role ? (
                            <FormFeedback type="invalid">
                              {validation.errors.user_role}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row className="mb-5">
              { !isMe &&
                <Col md="12">
                  <Button 
                    style={{ width: "100%" }}
                    text="Salvar Alterações"
                    isLoading={is_loading_post}
                  />
                </Col>
              }
            </Row>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UsersEditPage;
