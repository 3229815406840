import React from "react";
import { Card, CardBody, Col } from "reactstrap";

export const DataValueBox1 = ({ data }) => {
  return (
    <Col xl={3} sm={6}>
      <Card>
        <CardBody>
          <div className="d-flex text-muted">
            <div className="flex-grow-1 overflow-hidden">
              <p className="mb-1">{ data.title }</p>
              <h5 className="mb-3">{ data.value }</h5>
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
}