import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import logolight from "../../assets/images/logo-black.png";
import logodark from "../../assets/images/logo-black.png";

import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap";

import API from "../../services/api";

import { Link } from "react-router-dom";
import withRouter from "../../components/Common/withRouter";

import * as Yup from "yup";
import { useFormik } from "formik";

import {
  defaultTitle,
  defaultColors,
  defaultFooter,
  defaultError,
} from "../../constants/layout";

import { Button } from "../../components/Common/Button";
import { setLoggedinUser } from "../../helpers/api_helper";
import { hexToRGBA } from "../../helpers/general_helper";
import { defaultLoggedRoute } from "../../Routes/routes";

const Login = () => {
  document.title = `Login | ${defaultTitle}`;

  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState("");

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Por favor, informe seu e-mail")
        .email("Por favor, informe um e-mail válido"),
      password: Yup.string().required("Por favor, informe sua senha"),
    }),
    onSubmit: (values) => {
      doLogin(values);
    },
  });

  const doLogin = (values) => {
    setIsLoading(true);
    API.post("login", {
      username: values.email,
      password: values.password,
    })
    .then(({ data }) => {
      setError(false);
      setLoggedinUser(data);
      window.location = defaultLoggedRoute;
    })
    .catch(({response}) => {
      setIsLoading(false);
      setError(response?.data?.message || defaultError);
    });
  };

  useEffect(() => {
    document.body.className = "bg-pattern";
    return function cleanup() {
      document.body.className = "";
    };
  });

  return (
    <React.Fragment>
      <div className="bg-overlay"></div>
      <div className="account-pages my-5 pt-5">
        <Container>
          <Row className="justify-content-center">
            <Col lg={6} md={8} xl={4}>
              <Card
                style={{
                  backgroundColor: hexToRGBA('#FFFFFF', 0.9),
                }}
              >
                <CardBody className="p-4">
                  <div>
                    <div className="text-center mt-2">
                      <Link to="/">
                        <img
                          src={logodark}
                          alt=""
                          height="56"
                          className="auth-logo logo-dark mx-auto"
                        />
                        <img
                          src={logolight}
                          alt=""
                          height="56"
                          className="auth-logo logo-light mx-auto"
                        />
                      </Link>
                    </div>
                    <p className="mb-4 text-center text-black">
                      
                    </p>
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      {error ? (
                        <Alert color="danger">
                          <div>{error}</div>
                        </Alert>
                      ) : null}
                      <Row>
                        <Col md={12}>
                          <div className="mb-4">
                            <Label className="form-label text-black">
                              E-mail
                            </Label>
                            <Input
                              name="email"
                              className="form-control text-black"
                              placeholder="Informe seu e-mail"
                              type="email"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ""}
                              invalid={
                                validation.touched.email &&
                                validation.errors.email
                                  ? true
                                  : false
                              }
                              style={{
                                backgroundColor: "transparent",
                                borderColor: "#53385A",
                              }}
                            />
                            {validation.touched.email &&
                            validation.errors.email ? (
                              <FormFeedback type="invalid">
                                <div>{validation.errors.email}</div>
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-4">
                            <Label className="form-label text-black">
                              Senha
                            </Label>
                            <Input
                              name="password"
                              className="text-black"
                              value={validation.values.password || ""}
                              type="password"
                              placeholder="Informe a sua senha"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.password &&
                                validation.errors.password
                                  ? true
                                  : false
                              }
                              style={{
                                backgroundColor: "transparent",
                                borderColor: "#53385A",
                              }}
                            />
                            {validation.touched.password &&
                            validation.errors.password ? (
                              <FormFeedback type="invalid">
                                <div> {validation.errors.password} </div>
                              </FormFeedback>
                            ) : null}
                          </div>

                          <Row>
                            <Col></Col>
                            <Col className="col-7">
                              <div className="text-md-end mt-3 mt-md-0">
                                <Link
                                  to="/auth-recoverpw"
                                  className="text-black"
                                >
                                  <i className="mdi mdi-lock"></i> Esqueceu sua
                                  senha?
                                </Link>
                              </div>
                            </Col>
                          </Row>
                          <div className="d-grid mt-4">
                            <Button 
                              style={{
                                backgroundColor: defaultColors.PINK,
                              }}
                              text="Fazer Login"
                              isLoading={isLoading}
                            />
                          </div>
                          <div className="mt-4 text-center"></div>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p className="text-white-50">{defaultFooter}</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};
