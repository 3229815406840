import React from "react";
import { Card, CardBody, Col } from "reactstrap";
import ReactApexChart from "react-apexcharts";

export const ChartCircleBox1 = ({ data }) => {

  const series = [data.percent];
  const radialoptions = {
      chart: {
          type: 'radialBar',
          sparkline: {
              enabled: true
          }
      },
      dataLabels: {
          enabled: false
      },
      colors: ['#B452DA'],
      stroke: {
          lineCap: 'round'
      },
      plotOptions: {
          radialBar: {
              hollow: {
                  margin: 0,
                  size: '70%'
              },
              track: {
                  margin: 0,
              },
  
              dataLabels: {
                  name: {
                      show: false
                  },
                  value: {
                      offsetY: 5,
                      show: true
                  }
              }
          }
      }
  };

  return (
    <Col xl={3} sm={6}>
      <Card>
        <CardBody>
          <div className="d-flex text-muted">
            <div className="flex-shrink-0 me-3 align-self-center">
              <div id="radialchart-1" className="apex-charts" dir="ltr">
                <ReactApexChart
                  options={radialoptions}
                  series={series}
                  type="radialBar"
                  height="72"
                  width= "72"
                />
              </div>
            </div>

            <div className="flex-grow-1 overflow-hidden">
              <p className="mb-1">{ data.title }</p>
              <h5 className="mb-3">{ data.value }</h5>
              { data.comparative && 
                <>
                  <p className="text-truncate mb-0">
                    <span className={`text-${data.comparative.direction === 'up' ? 'success' : 'danger'} me-2`}>
                      {" "}
                      {data.comparative.value}{" "}
                      <i className={`ri-arrow-right-${data.comparative.direction}-line align-bottom ms-1`}></i>
                    </span>{" "}
                  </p>
                  <p className="text-truncate mb-0">
                    {data.comparative.text}
                  </p>
                </>
              }
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
}