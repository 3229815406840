import logosm from "../assets/images/logo-sm.png";
import { defaultTitle } from "../constants/layout";

export const aAlert = (message, type) => {
  const n = Math.floor(Math.random() * 9999) + 1;
  const wrapper = document.createElement('div');
  wrapper.id = `toast-id-${n}`;
  wrapper.innerHTML = `
    <div class="position-fixed top-0 end-0 p-3" style="z-index: 1005;">
      <div class="toast fade show" role="alert">
        <div class="toast-header">
          <strong class="me-auto">
            <img src="${logosm}" alt="" class="me-2" height="18">
            ${defaultTitle}
          </strong>
          <button type="button" class="btn-close" aria-label="Close" onClick="javascript: document.body.removeChild(document.getElementById('${wrapper.id}'))"></button>
        </div>
        <div color="primary" class="toast-body">
          ${message}
        </div>
      </div>
    </div>
  `;
  document.body.appendChild(wrapper);
  setTimeout(() => {
    const elementToRemove = document.getElementById(wrapper.id);
    if (elementToRemove) {
        document.body.removeChild(elementToRemove);
    }
  }, 5000);
};

export const formatDatetime = (dateTime) => {
  const date = new Date(dateTime);

  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based, so we add 1
  const year = date.getFullYear();
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');

  return `${day}/${month}/${year} ${hours}:${minutes}`;
}

export const formatDate = (date) => {
  try {
    return date.split(' ')[0].split('-').reverse().join("/");
  }
  catch(e){
    return date;
  }
}

export const getUrlParam = (param) => {
  const queryString = window.location.search;
  const params = new URLSearchParams(queryString);
  return params.get(param);
}

export const removeUrlParam = (param) => {
  let queryString = window.location.search;
  const params = new URLSearchParams(queryString);
  params.delete(param);
  queryString = params.toString();

  let newUrl = window.location.pathname;
  if (queryString) {
    newUrl += '?' + queryString;
  }

  window.history.replaceState({}, '', newUrl);
}

export const getThisMondayDate = () => {
  const today = new Date();
  const dayOfWeek = today.getDay();
  const diff = today.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1);

  const monday = new Date(today.setDate(diff));
  return monday.toISOString().split('T')[0];
}

export const getThisNextSundayDate = () => {
  const today = new Date();
  const dayOfWeek = today.getDay();
  const diff = 7 - dayOfWeek;

  const sunday = new Date(today.setDate(today.getDate() + diff));
  return sunday.toISOString().split('T')[0];
}

export const formatDateToString = (dateString) => {
  const months = [
    'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
    'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
  ];

  const [year, month, day] = dateString.split('-');
  const monthIndex = parseInt(month, 10) - 1; // Months in JavaScript are zero-based

  const date = new Date(year, monthIndex, day);
  const dayOfMonth = date.getDate();
  const monthName = months[monthIndex];

  return `${dayOfMonth} de ${monthName} de ${year}`;
}

export const imageToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
    reader.readAsDataURL(file);
  });
}

export const hexToRGBA = (hex, alpha) => {
  let r = 0, g = 0, b = 0;

  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    hex = hex.replace(/^#([A-Fa-f0-9])$/, (_, m1) => m1 + m1);

    const bigint = parseInt(hex.slice(1), 16);
    r = (bigint >> 16) & 255;
    g = (bigint >> 8) & 255;
    b = bigint & 255;
  }

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

export const treatNumberFromPermissionName = (permissionName) => {
  try {
    const s = permissionName.split(".");
    return s[s.length-1].trim();  
  }
  catch(e){
    return permissionName;
  }
}

export const getFirstAndLastDateOfCurrentMonth = () => {
  const currentDate = new Date();
  const firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  const lastDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

  const previousMonth = new Date(firstDay);
  previousMonth.setMonth(previousMonth.getMonth() - 1);

  return {
    first: previousMonth.toISOString().split('T')[0],
    last: lastDay.toISOString().split('T')[0],
  };
}

export const formatMoney = (currencyCode, amount) =>{
  try {
    return new Intl.NumberFormat(currencyCode, {
      style: 'currency',
      currency: currencyCode,
    }).format(amount);
  }
  catch(e){
    return `${amount}`;
  }
};

export const goalFormat = (funnelStage, value, typing = false) => {
  try {
    let formattedValue = value.toString();
    // const isMoney = funnelStage === 'conversion_financial';
    const isMoney = false;

    if(isMoney){
      if(typing){
        formattedValue = formattedValue.replace(/[^\d]/g, '');
        if (formattedValue === '') {
          formattedValue = '0.00';
        } else {
          const numericValue = parseInt(formattedValue, 10);
          formattedValue = (numericValue / 100).toFixed(2);
        }
      }
      else {
        formattedValue = formattedValue.replace(/[^0-9.]/g, '');
        if (formattedValue === '') {
          formattedValue = '0.00';
        } else {
          formattedValue = parseFloat(formattedValue).toFixed(2);
        }
      }

      formattedValue = parseFloat(formattedValue).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        currencyDisplay: 'symbol',
        minimumFractionDigits: 2
      });
    }
    else {
      formattedValue = formattedValue.replace(/\D/g, '');
    }
        
    return formattedValue;
  }
  catch(e){
    return value;
  }
};
