import axios from 'axios';
import { BACKEND_SERVER } from '../constants/env';
import { getLoggedinUser } from "../helpers/api_helper";

const API = axios.create({
  baseURL: BACKEND_SERVER,
  timeout: 50000,
});

API.interceptors.request.use(config => {
  const userProfileSession = getLoggedinUser();
  if (userProfileSession !== null) {
    config.headers.Authorization = `Bearer ${userProfileSession.token}`;
  }
  return config;
}, error => {
  return Promise.reject(error);
});

export default API;