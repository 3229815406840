import React from "react";
import { Navigate } from "react-router-dom";

// Auth
import Login from "../Pages/Authentication/Login";
import Logout from "../Pages/Authentication/Logout";
import RecoverPassword from "../Pages/Authentication/RecoverPassword";

// User Profile
import UserProfile from "../Pages/Authentication/user-profile";

// Users
import UsersPage from "../Pages/Users";
import UsersCreatePage from "../Pages/Users/create.js";
import UsersEditPage from "../Pages/Users/edit.js";

// Permissions
import PermissionsPage from "../Pages/Permissions";
import PermissionsCreatePage from "../Pages/Permissions/create.js";
import PermissionsEditPage from "../Pages/Permissions/edit.js";

// Campaigns - Google
import CampaignsGoogleAdsPage from "../Pages/Campaigns/googleAds.js";
import CampaignsGoogleAnalyticsPage from "../Pages/Campaigns/googleAnalytics.js";

// Campaigns - Meta
import CampaignsFacebookAdsPage from "../Pages/Campaigns/facebookAds.js";
import CampaignsInstagramPage from "../Pages/Campaigns/instagram.js";
import CampaignsFacebookPage from "../Pages/Campaigns/facebook.js";

// Campaigns - LinkedIn
import CampaignsLinkedInAdsPage from "../Pages/Campaigns/linkedinAds.js";
import CampaignsLinkedInPage from "../Pages/Campaigns/linkedin.js";

// Campaigns - Press Release
import CampaignsPressReleasePage from "../Pages/Campaigns/pressRelease.js";

// Uploads
import UploadsCrmLeadSheetPage from "../Pages/Uploads/crmLeadSheet.js";

// Executive Vision
import ExecutiveVisionPage from "../Pages/Campaigns/executiveVision.js";
import SeoPage from "../Pages/Campaigns/seo.js";

// Import Utility Pages
import ComingSoon from "../Pages/Utility/ComingSoon-Page";
import Error401Page from "../Pages/Utility/Error401-Page.js";

// Goals
import GoalsPage from "../Pages/Goals/index.js";
import GoalsCreatePage from "../Pages/Goals/create.js";
import GoalsEditPage from "../Pages/Goals/edit.js"

const defaultLoggedRoute = '/executive-vision';

const authProtectedRoutes = [
  // dashboard
  { path: "/dashboard", component: <ComingSoon /> },

  // Profile
  { path: "/userprofile", component: <UserProfile /> },
  
  // error
  { path: "/error/401", component: <Error401Page /> },

  // users
  { path: "/users", component: <UsersPage /> },
  { path: "/users/create", component: <UsersCreatePage /> },
  { path: "/users/edit/:id", component: <UsersEditPage /> },
  
  // roles
  { path: "/permissions", component: <PermissionsPage /> },
  { path: "/permissions/create", component: <PermissionsCreatePage /> },
  { path: "/permissions/edit/:id", component: <PermissionsEditPage /> },
  
  // campaigns - google
  { path: "/campaigns/google/ads", component: <CampaignsGoogleAdsPage /> },
  { path: "/campaigns/google/analytics", component: <CampaignsGoogleAnalyticsPage /> },
  
  // campaigns - meta
  { path: "/campaigns/meta/instagram", component: <CampaignsInstagramPage /> },
  { path: "/campaigns/meta/facebook", component: <CampaignsFacebookPage /> },
  { path: "/campaigns/meta/ads", component: <CampaignsFacebookAdsPage /> },
  
  // campaigns - linkedIn
  { path: "/campaigns/linkedin", component: <CampaignsLinkedInPage /> },
  { path: "/campaigns/linkedin/ads", component: <CampaignsLinkedInAdsPage /> },
  
  // campaigns - press release
  { path: "/campaigns/press-release", component: <CampaignsPressReleasePage /> },
  
  // campaigns - executive vision
  { path: "/executive-vision", component: <ExecutiveVisionPage /> },

  // uploads - crm sheet
  { path: "/uploads/crm-sheet", component: <UploadsCrmLeadSheetPage /> },

  // goals
  { path: "/goals", component: <GoalsPage /> },
  { path: "/goals/create", component: <GoalsCreatePage /> },
  { path: "/goals/edit/:id", component: <GoalsEditPage /> },

  // SEO
  { path: "/seo", component: <SeoPage /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to={defaultLoggedRoute} />,
  },
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/auth-recoverpw", component: <RecoverPassword /> },
];

export { authProtectedRoutes, publicRoutes, defaultLoggedRoute };
