import React from "react";
import { Card, CardBody, Col, Progress } from "reactstrap";

export const Target = ({ data }) => {
  return (
    <Col xl={6} sm={12}>
      <Card>
        <CardBody style={{ padding: 0 }}>
          <div className="d-flex align-items-center">
            <div className="text-center" style={{ padding: "1.25rem", borderRightWidth: 1, borderRightColor: '#DFDFDF', borderRightStyle: 'solid' }}>
              <svg width="40" height="40" viewBox="0 0 30 30" fill={data.colorHex} xmlns="http://www.w3.org/2000/svg">
                <path d="M14.6469 14.0727L1.69248 17.755C0.774946 18.0156 0.667285 19.2731 1.52736 19.6856L5.66867 21.6749L0.275351 27.0682C-0.0917838 27.4347 -0.0917838 28.0299 0.275351 28.3964L1.60357 29.7246C1.97071 30.0912 2.56526 30.0912 2.93179 29.7246L8.32511 24.3313L10.3144 28.4726C10.7275 29.3327 11.9844 29.2251 12.245 28.3075L15.9267 15.3531C16.1493 14.5723 15.4277 13.8513 14.6469 14.0727V14.0727ZM16.3017 21.1511L15.4501 24.1481C20.3178 23.9092 24.1936 19.9269 24.1936 14.9999C24.1936 9.9181 20.0807 5.80642 15.0001 5.80642C10.0725 5.80642 6.09085 9.68342 5.85194 14.5505L8.8489 13.6989C9.45071 10.8532 11.9783 8.70963 15.0001 8.70963C18.4688 8.70963 21.2904 11.5312 21.2904 14.9999C21.2904 18.0217 19.1468 20.5493 16.3017 21.1511ZM15.0001 0C6.71564 0 0.000151205 6.71549 0.000151205 14.9999C0.000151205 15.4415 0.0285785 15.8763 0.0660783 16.3082C0.275351 16.189 0.489463 16.0741 0.730793 16.0058L2.92272 15.3828C2.91848 15.2546 2.90336 15.1294 2.90336 14.9999C2.90336 8.3298 8.32995 2.90321 15.0001 2.90321C21.6702 2.90321 27.0968 8.3298 27.0968 14.9999C27.0968 21.6701 21.6702 27.0966 15.0001 27.0966C14.8706 27.0966 14.7454 27.0815 14.6172 27.0773L13.9942 29.2692C13.9253 29.5124 13.8037 29.7228 13.6833 29.9333C14.1182 29.9714 14.5555 29.9998 15.0001 29.9998C23.2845 29.9998 30 23.2844 30 14.9999C30 6.71549 23.2845 0 15.0001 0Z" />
              </svg>
              <h4 className="mt-3">
                { data.total }
              </h4>
              <h6 style={{ color: data.colorHex }}>
                { data.subTitle }
              </h6>
            </div>
            <div className="d-flex" style={{ flex: 1, padding: "1.25rem" }}>
              <div style={{ flex: 1 }}>
                  <h4>
                    { data.title }
                  </h4>
                  { !data.error ?
                    <>
                      <p>
                        Meta: {data.totalGoal} <br />
                        Por dia: {data.totalPerDay} <br />
                        Periodo: {data.description}
                      </p>
                      <Progress
                        value={data.percent}
                        style={{ width: "100%" }}
                        color={ data.colorSys }
                        animated
                      ></Progress>
                    </>
                  :
                    <p dangerouslySetInnerHTML={{ __html: data.description }} style={{ color: '#7A7A7A' }} />
                  }
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
}